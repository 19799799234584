/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  background-color: white; 
  color: black; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-sf {
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", "Arial", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Keyframes for cursor blinking */
@keyframes blink {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

/* Optional Blinking cursor effect (if needed separately) */
.blinking-cursor {
  border-right: 2px solid black;
  display: inline;
  animation: blink 1s step-end infinite;
}

.navbar {
  position: sticky;
  top: 0; /* Sticks the navbar to the top of the viewport */
  z-index: 10; /* Ensures the navbar stays above other elements */
  background-color: white; /* Ensures the navbar has a background to stand out */
  padding: 1rem; /* Optional: Add some padding for better visuals */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
}



